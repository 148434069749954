import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { ScopedCssBaseline, Container } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import {
  clearActiveProvider,
  fetchActiveProvider,
} from '../../../actions/providers';
import theme from '../../../theme';
import ErrorSnackBar from '../../shared/ErrorSnackBar';
import { clearErrorMessage } from '../../../actions/errorMessage';
import LoadingMask from '../../shared/LoadingMask';
import PhysicalProviderForm from './PhysicalProviderForm';
import GCPProviderProviderForm from './GCPProviderForm';
import PROVIDER_TYPES from '../providerTypes';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AWSProviderForm from './AWSProviderForm';

export default function ProviderForm() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { provider_uuid } = useParams();
  const location = useLocation();
  const sp = new URLSearchParams(location.search);
  const provider = useSelector((state) => state.provider);
  const api_error = useSelector((state) => state.errorMessage);

  const type = provider?.type || sp.get('type');

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!provider && provider_uuid) {
      dispatch(fetchActiveProvider(provider_uuid));
    } else {
      setLoading(false);
    }
  }, [dispatch, provider_uuid, provider]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
      dispatch(clearActiveProvider());
    };
  }, [dispatch]);

  if (loading) {
    return <LoadingMask />;
  }

  switch (type) {
    case PROVIDER_TYPES.PHYSICAL.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <PhysicalProviderForm provider={provider} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.GCP.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <GCPProviderProviderForm provider={provider} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    case PROVIDER_TYPES.AWS.value:
      return (
        <ThemeProvider theme={theme}>
          <ScopedCssBaseline>
            <Container>
              <AWSProviderForm provider={provider} />
              <ErrorSnackBar message={api_error} />
            </Container>
          </ScopedCssBaseline>
        </ThemeProvider>
      );
    default:
      history.push('/resources');
      return <div>Redirecting...</div>;
  }
}
