const GCP_RESOURCE_TYPES = {
  L4: 'cad854db-c733-4449-b109-a59e258a5e6e',
  V100: '4daa38b5-df6f-47ae-b70e-6c16315aca0e',
  P100: 'db3af61b-f7c2-496b-81d6-64bbea58c2a8',
  T4: 'c735ed24-41af-41e7-94b6-57215768f068',
  A100: 'd99bf4f7-92b7-4622-9355-a7aa3f5469b5',
  A100_80GB: '0202cd5c-3767-4034-a590-799ecf94af48',
  CPU: '78201a90-ca48-4147-b6f5-fec51b4bd7db',
};
export default GCP_RESOURCE_TYPES;
