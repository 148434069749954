import React, { useState } from 'react';

import {
  Button,
  IconButton,
  Box,
  Typography,
  Grid,
  Toolbar,
  Divider,
  Paper,
  Stack,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { createProvider, updateProvider } from '../../../../actions/providers';
import PROVIDER_TYPES from '../../providerTypes';
import PaymentModeField from '../Shared/PaymentModeField';

const provider_type = PROVIDER_TYPES.PHYSICAL;

export default function PhysicalProviderForm({ provider }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [pristine, setPristine] = useState(false);
  const [payment_mode, setPaymentMode] = useState(
    provider?.payment_mode || 'credits'
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (provider) {
      await dispatch(updateProvider(provider.provider_uuid, { payment_mode }));
    } else {
      await dispatch(
        createProvider({ type: provider_type.value, payment_mode })
      );
    }

    history.push('/resources');
  };

  return (
    <Paper sx={{ display: 'flex', width: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
            <Toolbar>
              <Stack spacing={2} direction='row' alignItems='center'>
                <IconButton onClick={() => history.goBack()} disabled={loading}>
                  <ArrowBack />
                </IconButton>
                <Typography variant='h5'>{provider_type?.label}</Typography>
              </Stack>
            </Toolbar>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Box padding='15px' sx={{ maxWidth: 400 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant='h6'>General</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <PaymentModeField
                  value={payment_mode}
                  setValue={setPaymentMode}
                  setChanged={() => setPristine(false)}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Divider />
          <Toolbar>
            <Stack spacing={2} direction='row'>
              <LoadingButton
                variant='contained'
                color='brand'
                onClick={handleSubmit}
                loading={loading}
                disabled={pristine}
              >
                Submit
              </LoadingButton>

              <Button
                variant='outlined'
                onClick={() => history.goBack()}
                disabled={loading}
              >
                Cancel
              </Button>
            </Stack>
          </Toolbar>
        </Grid>
      </Grid>
    </Paper>
  );
}
